<template>
  <div>
    <el-submenu v-if="item.children&&item.children.length&&!item.other.parentId&&item.level===1&&item.children.some(v=>v.other.authorityType==='menu')" :index="item.id">
      <template slot="title">
        <i
          style="padding-right: 8px;"
          class="iconfont"
          :class="{'icon-lg':sidebarCollapsed,[item.icon]:true}"></i>
        <span class="not-select">{{item.label}}</span>
      </template>
      <template v-for="itemChild in item.children">
        <sidebar-item
          v-if="itemChild.children&&itemChild.children.length"
          :key="itemChild.code"
          :item="itemChild"></sidebar-item>
        <el-menu-item v-else :key="itemChild.id" :index="itemChild.id" @click="goto(itemChild)">
          <i class="iconfont circle" style="padding-right: 8px;"></i>
          <span class="not-select">{{itemChild.label}}</span>
        </el-menu-item>
      </template>
    </el-submenu>
    <template v-else-if="item.other.uniqueKey">
      <el-menu-item :index="item.id" :title="sidebarCollapsed?item.label:''" @click="goto(item)">
        <i class="iconfont circle" style="padding-right: 8px;"></i>
        <span class="not-select">{{item.label}}</span>
      </el-menu-item>
    </template>
  </div>
</template>

<script>

  export default {
    name: "SidebarItem",
    props: {
      sidebarCollapsed: Boolean,
      item: {
        type: Object,
        required: true,
      },
    },
    methods: {
      goto(menu) {
        console.log(menu,4777777777);
        this.$router.push({path: menu.other.uniqueKey});
      },
    },
  };
</script>
<style lang="scss" scoped>
  .not-select {
    user-select: none;
  }
</style>