<template>
  <div class="vm-main">
    <sidebar
      :sidebar-collapsed="sidebarCollapsed"
      class="vm-main-sidebar"></sidebar>
    <head-bar
      ref="headBar"
      :class="{'vm-header-collapsed':sidebarCollapsed}"
      class="vm-main-header"
      @toggle-sidebar="toggleSidebar"></head-bar>
    <div
      :class="{'vm-main-collapsed':sidebarCollapsed}"
      class="vm-main-page">
      <div class="vm-content">
        <router-view class="vm-page"></router-view>
      </div>
    </div>
  </div>
</template>
<script>
  import Sidebar from "./Sidebar.vue";
  import HeadBar from "./HeadBar.vue";
  import moment from "moment";
  import loginUtil from "@/util/loginUtil";
  const storage = window.localStorage;

  export default {
    components: {
      Sidebar,
      HeadBar,
    },
    data() {
      return {
        sidebarCollapsed: storage.getItem("sidebarCollapsed") === "true",
        timer:null,
        locked:false,
        id:0,
        record:[],
      };
    },
    mounted() {

      // this.getRepair();
      // this.setTime();
      // this.init();
    },
    created() {
      const os = localStorage.getItem("os");
      if (os==="new") {
        this.$router.push({path: "/index/index"});
      }
    },
    // destroyed() {
    //   window.clearInterval(this.timer);
    //   window.clearInterval(this.time);
    //   this.time = null;
    //   console.log(61);
    //   this.socket.close();
    //   this.timer=null;
    // },
    methods: {
      init: function() {
        console.log(60);
        const userInfo = JSON.parse(localStorage.getItem("hold_user_info"));
        const id = userInfo.user.id;
        if(typeof(WebSocket) === "undefined") {
          alert("您的浏览器不支持socket");
        }else{
          // 实例化socket
          console.log(window.config.wsUrl+"/webMessage/"+id);
          this.socket = new WebSocket(window.config.wsUrl+"/webMessage/"+id);
          // 监听socket连接
          this.socket.onopen = this.open;
          // 监听socket错误信息
          this.socket.onerror = this.error;
          // 监听socket消息
          this.socket.onmessage = this.message;
          this.socket.onclose = this.close;


          this.time = window.setInterval(()=>{
            console.log(78);
            try {
              console.log(82,this.socket);
              this.socket.send("hearbeats");
            }catch (e) {
              console.log(85,e);
              console.log(this.socket);
              console.log(this.init);
              window.clearInterval(this.time);
              this.time = null;
              this.init();
            }
          },30000);
        }
      },
      open: function() {
        this.socket.send("hearbeats");
        this.$notify.success("连接成功,推送通知功能开启");
        console.log("socket连接成功");
      },
      error: function(err) {
        window.clearInterval(this.time);
        this.time = null;
        this.init();
        console.log("连接错误:"+err);
      },
      message: function(msg) {
        try {
          const data = JSON.parse(msg.data);
          console.log(109,data);
          if (data.records instanceof Array && data.records.length>0) {
            console.log(105);
            this.$notify.error({
              title:  `${data.records[0]?.realEstateName}-${data.records[0]?.buildingName}-发生故障`,
              message: `${data.records[0]?.faultCode}-${data.records[0]?.orderCode}-于${moment(data.records[0]?.createTime).format("YYYY-MM-DD HH:mm:ss")}生成工单`,
              duration: 0,
              onClick:()=>{
                // console.log(e,51);
                this.$router.push({path: "/maintainplan/repairorder",query:{orderCode: data.records[0]?.orderCode}});
              },
            });
          }
        }catch (e) {
          console.log(99);
        }
        console.log(msg.data,typeof msg.data);
      },
      // 发送消息给被连接的服务端
      send: function(params) {
        this.socket.send(params);
      },
      close: function(CloseEvent) {
        window.clearInterval(this.time);
        this.time = null;

        if(CloseEvent.code!==1000) {
          this.init();
        }else {
          // this.$notify.error({
          //   title:  "推送通知已关闭",
          //   message: "由于系统更新或者他人使用本账号导致推送功能关闭，请点击我重新链接",
          //   duration: 0,
          //   onClick:()=>{
          //     // console.log(e,51);
          //     this.init();
          //   },
          // });
        }
        console.log("socket已经关闭",CloseEvent);
      },


      setTime() {
        this.timer = window.setInterval(()=>{
          this.getMessage();
          // console.log(42);
        },3000);
      },
      getMessage() {
        let arr = [];
        let obj={pageSize:1,pageNo:1,orderColumnName:"reportedTime",reportedTimeFrom:moment().format("YYYY-MM-DD"+" 00:00:00")};

        const userInfo = JSON.parse(localStorage.getItem("hold_user_info"));
        if (userInfo.customer?.realEstates) {
          if (userInfo.customer.realEstates instanceof Array) {
            arr = userInfo.customer.realEstates;
            this.$http.get("maintenance/repair-report/page",obj).then(res=>{
              // console.log(res.records[0],44);
              if (this.record.some(item=>item.orderCode===res.records[0].orderCode)) {
                // this.record.push(res.records[0]);
              }else {
                this.record.push(res.records[0]);
                if(arr instanceof Array && arr.length===0 && res.records[0]?.orderCode) {
                  this.$notify.error({
                    title:  `${res.records[0]?.realEstateName}-${res.records[0]?.buildingName}-发生故障`,
                    message: `${res.records[0]?.faultPhenomenon}-${res.records[0]?.orderCode}-于${res.records[0]?.reportedTime}生成工单`,
                    duration: 0,
                    onClick:()=>{
                      // console.log(e,51);
                      this.$router.push({path: "/maintainplan/repairorder",query:{orderCode: res.records[0]?.orderCode}});
                    },
                  });
                }else if (arr instanceof Array && arr.length>0) {
                  if (arr.some(item=>item.id ===res.records[0]?.realEstateId)) {
                    this.$notify.error({
                      title:  `${res.records[0]?.realEstateName}-${res.records[0]?.buildingName}-发生故障`,
                      message: `${res.records[0]?.faultPhenomenon}-${res.records[0]?.orderCode}-于${res.records[0]?.reportedTime}生成工单`,
                      duration: 0,
                      onClick:()=>{
                        // console.log(e,51);
                        this.$router.push({path: "/maintainplan/repairorder",query:{orderCode: res.records[0]?.orderCode}});
                      },
                    });
                  }
                }
              }
            });

          }
        }else {
          obj = {...obj,companyId:loginUtil.getCompanyCode()};
          this.$http.get("/maintenance/repair-report/page",obj).then(res=>{
            // console.log(res.records[0],44);
            if (this.record.some(item=>item.orderCode===res.records[0].orderCode)) {
              // this.record.push(res.records[0]);
            }else {
              this.record.push(res.records[0]);
              if(arr instanceof Array && arr.length===0 && res.records[0]?.orderCode) {
                this.$notify.error({
                  title:  `${res.records[0]?.realEstateName}-${res.records[0]?.buildingName}-发生故障`,
                  message: `${res.records[0]?.faultPhenomenon}-${res.records[0]?.orderCode}-于${res.records[0]?.reportedTime}生成工单`,
                  duration: 0,
                  onClick:()=>{
                    // console.log(e,51);
                    this.$router.push({path: "/maintainplan/repairorder",query:{orderCode: res.records[0]?.orderCode}});
                  },
                });
              }else if (arr instanceof Array && arr.length>0) {
                if (arr.some(item=>item.id ===res.records[0]?.realEstateId)) {
                  this.$notify.error({
                    title:  `${res.records[0]?.realEstateName}-${res.records[0]?.buildingName}-发生故障`,
                    message: `${res.records[0]?.faultPhenomenon}-${res.records[0]?.orderCode}-于${res.records[0]?.reportedTime}生成工单`,
                    duration: 0,
                    onClick:()=>{
                      // console.log(e,51);
                      this.$router.push({path: "/maintainplan/repairorder",query:{orderCode: res.records[0]?.orderCode}});
                    },
                  });
                }
              }
            }
          });

        }
      },
      getRepair() {
        this.$http.get("maintenance/repair-report/page",{pageSize:1000,pageNo:1,orderColumnName:"reportedTime",reportedTimeFrom:moment().format("YYYY-MM-DD"+" 00:00:00")}).then(res=>{
          // console.log(res.records[0],44);
          this.record = res.records;
        },
        );
      },
      toggleSidebar() {
        this.sidebarCollapsed = !this.sidebarCollapsed;
        storage.setItem("sidebarCollapsed", this.sidebarCollapsed);
      },
    },
  };
</script>
<style lang="scss">
  @import "../../style/variables";

  .vm-main {
    position: absolute;
    width: 100%;
    height: 100%;

    .vm-main-sidebar {
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
    }

    .vm-main-header {
      box-sizing: border-box;
      position: relative;
      left: $sidebar-width;
      width: calc(100% - #{$sidebar-width});
      height: $header-height;
      transition: all 0.3s;
    }

    .vm-main-page {
      position: absolute;
      top: $header-height;
      left: $sidebar-width;
      right: 0;
      bottom: 0;
      overflow: auto;
      padding: 10px;
      transition: left 0.3s;
      background-color: #ebeef2;

      .vm-content {
        width: 100%;
        border-radius: 4px;
        background-color: white;

        .vm-page {
          padding: 13px 20px;
        }
      }
    }

    .vm-header-collapsed {
      left: $sidebar-width-close;
      width: calc(100% - #{$sidebar-width-close});
    }

    .vm-main-collapsed {
      left: $sidebar-width-close;
    }
  }
</style>
