<template>
  <header class="header">
    <i class="iconfont nav item" @click="toggleSidebar"></i>
    <div class="title">{{$route.meta.title}}</div>
    <span class="center"></span>
    <el-tooltip
      v-if="!isWsSuccess"
      class="item"
      effect="dark"
      content="点击重启消息服务"
      placement="top">
      <div @click="init">
        <el-icon class="el-icon-message-solid" style="margin-right: 10px;font-size: 30px;color: red;cursor: pointer"></el-icon>
      </div>
    </el-tooltip>
    <el-icon v-else class="el-icon-message-solid" style="margin-right: 10px;font-size: 30px;color: green"></el-icon>
    <el-select v-if="parentId" v-model="value" style="margin-right: 2px;width: 200px" @change="changeCompanyId">
      <el-option
        v-for="item in options"
        :key="item.id"
        :label="item.name"
        :value="item.id">
      </el-option>
    </el-select>

    <!--    <Button type="text" style="margin-right: 2px" @click="gotoNew">前往新版</Button>-->
    <!--    <el-select v-model="$i18n.locale" :placeholder="$l('common.select','请选择')" style="width: 100px;margin-right: 10px" @change="saveLang">-->
    <!--      <el-option-->
    <!--        v-for="item in languages"-->
    <!--        :key="item.value"-->
    <!--        :label="item.label"-->
    <!--        :value="item.value">-->
    <!--      </el-option>-->
    <!--    </el-select>-->

    <el-dropdown style="margin-right: 10px" trigger="click" @command="handleCommand">
      <el-button size="mini">
        {{name}}<i class="el-icon-arrow-down el-icon--right"></i>
      </el-button>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="changePwd">{{$l("main.changePassword","修改密码")}}</el-dropdown-item>
        <el-dropdown-item command="logout">{{$l("main.logout","注销")}}</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <change-pwd ref="changePwdDialog"></change-pwd>
  </header>
</template>

<script>
  import auth from "@/util/loginUtil";
  import ChangePwd from "./ChangePwd";
  import router from "@/router/router";
  import loginUtil from "@/util/loginUtil";
  import moment from "moment/moment";

  export default {
    components: {ChangePwd},
    data() {
      return {
        name: auth.getUsername(),
        languages: [{label: "中文简体", value: "zh-CN"}, {label: "English", value: "en-US"}],
        parentId: null,
        options:[],
        value:"",
        isWsSuccess: false,
      };
    },
    mounted() {
      this.init();
      if (loginUtil.getUserInfo().employee&&!loginUtil.getUserInfo().employee.parentId) {
        this.parentId = true;
        this.getCompany();
        this.value= loginUtil.getCompanyCode();
      }
    },
    destroyed() {
      window.clearInterval(this.timer);
      window.clearInterval(this.time);
      this.time = null;
      console.log(61);
      this.socket.close();
      this.timer=null;
    },
    methods: {
      getCompany() {
        if (localStorage.getItem("companyList")) {
          try {
            if (JSON.parse(localStorage.getItem("companyList")).length>0) {
              this.options = JSON.parse(localStorage.getItem("companyList"));
            }else {
              this.$http.get("/organization/company/page").then(res=>{
                localStorage.setItem("companyList",JSON.stringify(res.records));
                this.options=res.records;
              });
            }

          } catch (e) {
            this.$http.get("/organization/company/page").then(res=>{
              localStorage.setItem("companyList",JSON.stringify(res.records));
              this.options=res.records;
            });
          }
        }else {
          this.$http.get("/organization/company/page").then(res=>{
            localStorage.setItem("companyList",JSON.stringify(res.records));
            this.options=res.records;
          });
        }

      },
      changeCompanyId(item) {
        this.value=item;
        const i =  this.options.filter(itm=>itm.id ===item)[0];
        console.log(item, 64);
        const userInfo=JSON.parse(localStorage.getItem("hold_user_info"));
        userInfo.employee.companyId = i.id;
        userInfo.employee.companyName = i.name;
        localStorage.setItem("hold_user_info",JSON.stringify(userInfo));
        this.$message.success("切换成功，将以"+i.name+"身份进行查询");
        location.reload(true);
      },
      handleCommand(command) {
        switch (command) {
          case "logout":
            this.$confirm(this.$l("main.logoutTip", "确定退出登录吗?"), this.$l("common.tip", "提示"), {
              type: "warning",
            }).then(() => {
              this.logout();
            });
            break;
          case "changePwd":
            this.$refs.changePwdDialog.open();
            break;
        }
      },
      toggleSidebar() {
        this.$emit("toggle-sidebar");
      },
      logout() {
        localStorage.removeItem("companyList");
        auth.logout();
        location.reload();
      },
      saveLang(lang) {
        window.localStorage.setItem("local", lang);
        location.reload();
      },
      gotoNew() {
        localStorage.setItem("os","new");
        router.push("/index/index");
      },
      init: function() {
        console.log(60);
        const userInfo = JSON.parse(localStorage.getItem("hold_user_info"));
        const id = userInfo.user.id;
        if(typeof(WebSocket) === "undefined") {
          alert("您的浏览器不支持socket");
        }else{
          // 实例化socket
          console.log(window.config.wsUrl+"/webMessage/"+id);
          this.socket = new WebSocket(window.config.wsUrl+"/webMessage/"+id);
          // 监听socket连接
          this.socket.onopen = this.open;
          // 监听socket错误信息
          this.socket.onerror = this.error;
          // 监听socket消息
          this.socket.onmessage = this.message;
          this.socket.onclose = this.close;


          this.time = window.setInterval(()=>{
            console.log(78);
            try {
              console.log(82,this.socket);
              this.socket.send("hearbeats");
            }catch (e) {
              console.log(85,e);
              console.log(this.socket);
              console.log(this.init);
              window.clearInterval(this.time);
              this.time = null;
              this.init();
            }
          },30000);
        }
      },
      open: function() {
        this.socket.send("hearbeats");
        this.$notify.success("连接成功,推送通知功能开启");
        this.isWsSuccess = true;
        console.log("socket连接成功");
      },
      error: function(err) {
        window.clearInterval(this.time);
        this.time = null;
        this.init();
        console.log("连接错误:"+err);
      },
      message: function(msg) {
        try {
          const data = JSON.parse(msg.data);
          console.log(109,data);
          if (data.records instanceof Array && data.records.length>0) {
            console.log(105);
            this.$notify.error({
              title:  `${data.records[0]?.realEstateName}-${data.records[0]?.buildingName}-发生故障`,
              message: `${data.records[0]?.faultCode}-${data.records[0]?.orderCode}-于${moment(data.records[0]?.createTime).format("YYYY-MM-DD HH:mm:ss")}生成工单`,
              duration: 0,
              onClick:()=>{
                // console.log(e,51);
                this.$router.push({path: "/maintainplan/repairorder",query:{orderCode: data.records[0]?.orderCode}});
              },
            });
          }
        }catch (e) {
          console.log(99);
        }
        console.log(msg.data,typeof msg.data);
      },
      // 发送消息给被连接的服务端
      send: function(params) {
        this.socket.send(params);
      },
      close: function(CloseEvent) {
        window.clearInterval(this.time);
        this.time = null;
        this.isWsSuccess = false;
        // if(CloseEvent.code!==1000) {
        //   this.init();
        // }else {
        //   // this.$notify.error({
        //   //   title:  "推送通知已关闭",
        //   //   message: "由于系统更新或者他人使用本账号导致推送功能关闭，请点击我重新链接",
        //   //   duration: 0,
        //   //   onClick:()=>{
        //   //     // console.log(e,51);
        //   //     this.init();
        //   //   },
        //   // });
        // }
        console.log("socket已经关闭",CloseEvent);
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "../../style/variables";

  .header {
    display: flex;
    align-items: center;
    height: $header-height;
    border-bottom: 1px solid #eeeeee;

    .center {
      flex: 1;
    }
    .title{
      font-size: 16px;
    }

    .item {
      line-height: $header-height;
      height: $header-height;
      padding: 0 15px;
      cursor: pointer;
      display: block;
      color: #495060;
      font-size: 20px;

      &:hover {
        color: $color-primary;
      }
    }

    .action {
      margin-right: 10px;
    }

    .mqtt-status {
      font-size: 12px;
      color: #888;
      margin-right: 10px;
    }
  }
</style>
