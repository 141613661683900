<template>
  <el-dialog
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    :title="$l('main.changePassword','修改密码')"
    width="460px"
    @close="dialogClose">
    <el-form ref="form" :model="changePwd" size="small">
      <el-form-item prop="oldPassword" :rules="$rule.notNull">
        <el-input v-model="changePwd.oldPassword" :placeholder="$l('common.enter','请输入')" type="password" show-password>
          <template slot="prepend">{{$l("main.oldPassword","原密码")}}</template>
        </el-input>
      </el-form-item>
      <el-form-item prop="newPassword" :rules="[$rule.notNull,$rule.password]">
        <el-input v-model="changePwd.newPassword" :placeholder="$l('common.enter','请输入')" type="password" show-password>
          <template slot="prepend">{{$l("main.newPassword","新密码")}}</template>
        </el-input>
      </el-form-item>
      <el-button
        :loading="submitLoading"
        size="small"
        style="width: 100%"
        type="primary"
        @click="submit">
        {{$l("common.sure","提交")}}
      </el-button>
    </el-form>
  </el-dialog>
</template>

<script>

  export default {
    data() {
      return {
        dialogVisible: false,
        submitLoading: false,
        changePwd: {
          oldPassword: "",
          newPassword: "",
        },
      };
    },
    methods: {
      open() {
        this.filter={};
        this.dialogVisible = true;
      },
      dialogClose() {
        this.$refs.form.resetFields();
      },
      submit() {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.submitLoading = true;
            this.$http
              .put("/authentication/user/password", this.changePwd)
              .then(() => {
                this.submitLoading = false;
                this.dialogVisible = false;
                this.$message.success("密码修改成功");
              })
              .catch(() => {
                this.submitLoading = false;
              });
          }
        });
      },
    },
  };
</script>
